import React from 'react'
import Breadcrumbs from '../../components/Breadcrumbs'
import ImageHeader from '../../components/ImageHeader'
import IndexFilter from '../../components/IndexFilter'
import Layout from "../../components/Layout"
import Seo from '../../components/Seo'
import {useStaticQuery, graphql} from 'gatsby'
import '../../styles/index.scss'

const TopicsPage = () => {
    const data = useStaticQuery(graphql`
        query TopicQuery {
            allWpTopic {
                nodes {
                    name
                    id
                    slug
                    parentDatabaseId
                    count
                    wpChildren {
                        nodes {
                            name
                            slug
                            count
                            mereResources {
                                nodes {
                                    title
                                    slug
                                    resourceTypes {
                                        nodes {
                                            name
                                            slug
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            wp {
                resourcesGeneralSettings {
                    resourceLibraryHeader {
                        headerImage {
                            sourceUrl
                        }
                    }
                }
            }
        }
    `)

    //get all topics with a parent of null and map them
    //while mapping parent topics, find and map all children topics with matching parent dbid
    return (
        <Layout>
            <Seo
                title="Topics"
                description="Topics Index"
            />
            <ImageHeader title="Topic Index"
                         image={data.wp.resourcesGeneralSettings.resourceLibraryHeader.headerImage.sourceUrl}/>
            <Breadcrumbs title="Topics" isResource={true} />
            <IndexFilter taxonomy="topics" />
            <section className="index container">
                {data.allWpTopic.nodes.filter((term) => term.parentDatabaseId === null).filter((term) => term.count > 0).map((term, index) =>
                    <div key={index} className="index__group">
                        <div className="index__group__title">
                            <div className="index__group__title__first">
                                <h5><a href={`/resources/topics/${term.slug}`}>{term.name}</a></h5>
                            </div>
                            {term.count &&
                            <span>
                                    {`${term.count.toString()} resources`}
                                </span>
                            }
                        </div>
                        {term.wpChildren.nodes &&
                        <ul className="index__group__children">
                            {term.wpChildren.nodes.filter((child) => child.count > 0).map((child, index) =>
                                <li key={index} className="index__group__children__child">
                                    <a href={`/resources/topics/${child.slug}`}>
                                        <div>
                                            {child.name}
                                        </div>
                                        <span>
                                            {`${child.count.toString()} resources`}
                                        </span>
                                    </a>
                                </li>
                            )}
                        </ul>
                        }
                    </div>
                )}
            </section>
            <Breadcrumbs title="Topics" isResource={true} />
        </Layout>
    )
}

export default TopicsPage